import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { Spacing, TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import makeSpacingStyles from "@assets/style/util/makeSpacingStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import { DiscoIcon, DiscoIconKinds, DiscoTooltip, DiscoTooltipProps } from "@disco-ui"
import DiscoText from "@disco-ui/text/DiscoText"
import { Chip, ChipProps, Theme } from "@material-ui/core"
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import { Skeleton, SkeletonProps } from "@material-ui/lab"
import { forwardRef, ReactNode } from "react"

export type DiscoChipColor =
  | "grey"
  | "green"
  | "red"
  | "yellow"
  | "orange"
  | "purple"
  | "blue"
  | "cyan"
  | "primary"

export type DiscoChipProps = Omit<ChipProps, "label" | "color"> &
  Spacing & {
    label: ReactNode
    color?: DiscoChipColor
    testid?: string
    /** Default is 'caption' */
    textVariant?: TextVariantWithModifiers
    borderRadius?: keyof Theme["measure"]["borderRadius"]
    leftIcon?: ReactNode | DiscoIconKinds
    rightIcon?: ReactNode | DiscoIconKinds
    customTextColor?: CSSProperties["color"]
    customBackgroundColor?: CSSProperties["backgroundColor"]
    tooltip?: DiscoTooltipProps
  }

const DiscoChip = forwardRef<HTMLDivElement, DiscoChipProps>((props, ref) => {
  const {
    testid,
    label,
    color = "grey",
    classes: propsClasses,
    textVariant = "body-xs-500",
    leftIcon,
    rightIcon,
    borderRadius,
    customTextColor,
    customBackgroundColor,
    tooltip = {
      disabled: true,
      content: "",
    },

    // Spacing Props
    margin = 0,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding = 0,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    ...rest
  } = props

  const spacingClasses = useSpacingStyles({
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  })
  const classes = useStyles({
    borderRadius,
    color,
    customTextColor,
    customBackgroundColor,
  })

  return (
    <DiscoTooltip {...tooltip}>
      <Chip
        ref={ref}
        data-testid={`${testid}.chip`}
        {...rest}
        classes={mergeClasses(
          { root: classes.root, label: classes.label, icon: classes.icon },
          propsClasses,
          spacingClasses,
          {
            root: classes.variant,
            deleteIcon: classes.icon,
            icon: classes.icon,
          }
        )}
        label={
          <div className={classes.labelContainer}>
            {leftIcon && (
              <span className={classes.leftIconContainer}>
                {typeof leftIcon === "string" ? (
                  <DiscoIcon
                    className={classes.leftIcon}
                    icon={leftIcon as DiscoIconKinds}
                    height={16}
                    width={16}
                  />
                ) : (
                  leftIcon
                )}
              </span>
            )}
            {typeof label === "string" || typeof label === "number" ? (
              <DiscoText
                // Colours must be set to `!important` since custom themes overwrite the text colour
                classes={{ root: classes.variant }}
                variant={textVariant}
                data-testid={testid}
              >
                {label}
              </DiscoText>
            ) : (
              label
            )}

            {rightIcon && (
              <span className={classes.rightIconContainer}>
                {typeof rightIcon === "string" ? (
                  <DiscoIcon
                    className={classes.rightIcon}
                    icon={rightIcon as DiscoIconKinds}
                    height={16}
                    width={16}
                  />
                ) : (
                  rightIcon
                )}
              </span>
            )}
          </div>
        }
      />
    </DiscoTooltip>
  )
})

export const DiscoChipSkeleton = (
  props: Pick<SkeletonProps, "width" | "className"> & Pick<DiscoChipProps, "color">
) => {
  const { color = "blue", ...rest } = props
  const { root } = useStyles({ borderRadius: "xl", color })
  return <Skeleton variant={"rect"} classes={{ root }} {...rest} />
}

const useSpacingStyles = makeSpacingStyles()

type StyleProps = {
  borderRadius?: keyof Theme["measure"]["borderRadius"]
  color: DiscoChipColor
  customTextColor?: CSSProperties["color"]
  customBackgroundColor?: CSSProperties["backgroundColor"]
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ borderRadius }: StyleProps) => ({
    height: "24px",
    minWidth: "26px",
    borderRadius: borderRadius
      ? theme.measure.borderRadius[borderRadius]
      : theme.measure.borderRadius.xl,
    boxShadow: "none !important", // Overwrite Chip active styles
  }),
  label: {
    ...theme.typography["body-xs"],
    ...theme.typography.modifiers.fontWeight[500],
    padding: theme.spacing(0, 1),
  },
  icon: {
    // increase specificity to override DiscoIcon styles
    "&:is(svg)": {
      // mui sets the icon color - instead, inherit the color from the root element where we set the classes[color]
      color: "currentColor",
      width: "16px",
      height: "16px",
      marginLeft: theme.spacing(0.5),
    },
  },
  deleteIcon: {
    width: "16px",
    aspectRatio: "1",
  },
  variant: ({ color, customBackgroundColor, customTextColor }: StyleProps) => {
    const textColorIntensity = ["red", "orange", "blue", "purple"].includes(color)
      ? 600
      : 700
    const textColor =
      customTextColor ??
      (color === "primary"
        ? theme.palette.primary.main
        : theme.palette.groovy[color][textColorIntensity])
    const backgroundColor =
      customBackgroundColor ??
      (color === "primary"
        ? theme.palette.primary.light
        : theme.palette.type === "light"
        ? theme.palette.groovy[color][100]
        : theme.palette.groovy[color][200])
    return {
      color: `${textColor} !important`,
      "&, &:hover, &:focus": {
        backgroundColor,
      },
      "& svg": {
        color: textColor,
      },
    }
  },
  labelContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
  },
  leftIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  leftIcon: {
    display: "flex",
  },
  rightIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  rightIcon: {
    display: "flex",
  },
}))

export default DiscoChip
